<template>
  <div>
    <v-card>
      <v-card-title>Listado de plantas</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
          @eliminar="deleteAll"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable 
      :cabeceras="plantasListHeaders" 
      :items="plantasListBody" 
      titulo="Listado de Plantas" 
      label="Escriba Para buscar Plantas" 
      icono="list" 
      color_icono="azul" 
      :tachar="false"
      :acciones="['editar','eliminar']"
      @editar="editPlanta($event)"
      @eliminar="deletePlanta($event)">
    </datatable>

    <!-- Modal Nuevo Planta -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <form>
            <v-row class="mx-2">
              <v-col
                class="align-center justify-space-between"
                cols="4"
              >
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Nombre"
                  v-model="planta.nombre"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="savePlanta"
              >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de plantas desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadPlantas"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'
  import DataTable from '@/components/base/DataTable.vue';

  export default {
    data: () => ({
      title: 'Nueva Planta',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      search: null,
      file: null,
      dialog: false,
      upload: false,
      changeState: false,
      empresas: [],
      colors: [],
      sizes: [],
      state: null,
      states: [],
      plantaIndex: -1,
      planta: {
        id: 0,
        nombre: '',
      },
      plantasListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      plantasListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Planta'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadPlantas();
    },
    methods:{
      async loadPlantas(){
        this.mostrarLoading('Cargando listado de plantas...')
        let url = `${this.base_url}plantas`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.plantasListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async savePlanta(){
        // validaciones
        this.errors = [];
        if(!this.planta.nombre){
          this.errors.push("Debe indicar nombre del planta");
        }else if(this.planta.nombre.length > 30){
          this.errors.push("Tamaño máximo 30 caracteres");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}plantas`;
        let plantaData = {
          'nombre': this.planta.nombre,
        };
        if(this.planta.id > 0){
          url = url + "/" + this.planta.id;
          await this.axios.put(url, plantaData, this.headers).then((response)=>{
            let index = this.plantasListBody.map(planta => {
              return planta.id;
            }).indexOf(this.planta.id);
            this.plantasListBody.splice(index, 1, response.data);
            this.$store.commit('planta/updatePlanta', response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
              this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, plantaData, this.headers).then((response)=>{
              this.plantasListBody.push(response.data);
              this.$store.commit('planta/createPlanta', response.data);
              this.showSnackBar(false);
          }).catch((error)=>{
              this.errors = error.response.data;
              this.validation_message = this.errors.join();
              this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editPlanta(item) {
        this.plantaIndex = this.plantasListBody.indexOf(item);
        this.planta = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Planta";
      },
      async deletePlanta(item) {
        this.$confirm('Desea borrar este planta?').then(res => {
          if(res){
            let url = `${this.base_url}plantas/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.loadPlantas();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Planta";
      },
      cleanForm(){
        this.planta.id = 0;
        this.planta.nombre = "";
        this.planta.empresa = null;
        this.dialog = false;
      },
      async uploadPlantas(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/plantas`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null; 
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          this.loadPlantas();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/plantas`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.loadPlantas();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar este planta?').then(res => {
          if(res){
            let url = `${this.base_url}plantas/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadPlantas();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar este planta?').then(res => {
          if(res){
            let url = `${this.base_url}plantas/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadPlantas();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      'datatable': DataTable
    }
  }
</script>